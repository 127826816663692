import { api } from "@/instances/http";

class PaymentsService {
  paymentGatesBalances() {
    return api.get("/payments/paymentGatesBalances").then(response => {
      return response.data;
    });
  }
  getPayWaysWithdraw() {
    return api.get("/payments/getPayWaysWithdraw").then(response => {
      return response.data;
    });
  }
  getPaymentsSystems() {
    return api.get("/payments/paymentsSystems").then(response => {
      return response.data;
    });
  }
  findMany(params = {}) {
    return api.post("/payments/findMany", { ...params }).then(response => {
      return response.data;
    });
  }
  update(params = {}) {
    return api.post("/payments/update", { ...params }).then(response => {
      return response.data;
    });
  }
  findUnique(params = {}) {
    return api.post("/payments/findUnique", { ...params }).then(response => {
      return response.data;
    });
  }
  findFirst(params = {}) {
    return api.post("/payments/findFirst", { ...params }).then(response => {
      return response.data;
    });
  }
  withdraw(params = {}) {
    return api.post("/payments/withdraw", { ...params }).then(response => {
      return response.data;
    });
  }
  cancelWithdraw(params = {}) {
    return api
      .post("/payments/cancelWithdraw", { ...params })
      .then(response => {
        return response.data;
      });
  }
  getMethodWithdraw(params = {}) {
    return api
      .post("/auto/withdraw/methodWithdraw", { ...params })
      .then(response => {
        return response.data;
      });
  }
  getStatisticWithdrawAdmin(params = {}) {
    return api
      .post("/payments/getStatisticWithdrawAdmin", { ...params })
      .then(response => {
        return response.data;
      });
  }
  getStatisticDepositUsers(params = {}) {
    return api
      .post("/payments/getStatisticDepositUsers", { ...params })
      .then(response => {
        return response.data;
      });
  }
  saveChequeWithdraw(params = {}) {
    return api
        .post("/payments/saveChequeWithdraw", { ...params })
        .then(response => {
          return response.data;
        });
  }
}

export default new PaymentsService();
