<template>
  <v-row>
    <v-col>
      <v-card>
        <v-dialog v-model="loadCheckModal" max-width="400">
          <v-card>
            <v-card-title class="headline"
            >Загрузка чеков</v-card-title
            >
            <v-card-text>
              <div style="display: flex">
                <v-file-input
                    v-model="file"
                    label="Выбирите файл"
                    accept="image/*"
                    outlined
                ></v-file-input>
                <v-btn color="primary" @click="addCheckToList"
                >+</v-btn
                >
              </div>
              <h3 style="color: black">Выбраны файлы:</h3>
              <hr class="mt-2" />
              <div v-for="(check, key) in checksList" :key="key">
                <div style="display: flex">
                  <div
                      style="overflow: hidden; width: 280px; white-space: nowrap; text-overflow: ellipsis;"
                  >
                    {{ check.name }}
                  </div>
                  <v-btn
                      color="red"
                      style="margin-left: auto;"
                      @click="deleteCheckFromList(key)"
                  >-</v-btn
                  >
                </div>
                <hr />
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  @click="loadCheckModal = false"
              >
                Ок
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card-title>
          <v-tabs v-model="tab">
            <v-tab>В процессе</v-tab>
            <v-tab>Выполненно</v-tab>
            <v-tab>Последние созданные депоизты</v-tab>
            <v-tab>Последние депоизты</v-tab>
            <v-tab>Статистика депозитов</v-tab>
            <v-tab>Статистика выводов</v-tab>
          </v-tabs>
        </v-card-title>
        <v-col cols="12" sm="4" class="pb-0 pt-0">
          <v-text-field
            v-if="tab !== 4 && tab !== 5"
            v-model="search"
            :label="
              `Поиск по ID${tab === 0 || tab === 1 ? ' или рекивизитам' : ''}`
            "
            single-line
          />
        </v-col>
        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-data-table
                :headers="headersWithdraw"
                :items="filteredTransactionsInProccess"
                :loading="loading"
                :items-per-page="18"
                loading-text="Загрузка... Пожалуйста подождите"
              >
                <template v-slot:item.user="{ item }">
                  <router-link tag="div" :to="`/users/${item.cards.id}`">
                    <v-btn color="primary"> {{ item.cards.nick }}</v-btn>
                  </router-link>
                </template>
                <template v-slot:item.commentFromAdmin="{ item }">
                  <div v-if="item.comment && item.comment.includes('bank_name')">
                    СБП - {{ JSON.parse(item.comment).bank_name }};
                  </div>
                  <div v-if="item.comment && !item.comment.includes('bank_name')">
                    {{ item.comment }}
                  </div>
                  <div v-if="item.commentFromAdmin">
                    Комментарий - {{ item.commentFromAdmin }}
                  </div>
                </template>
                <template v-slot:item.operator="{ item }">
                  <router-link tag="div" :to="`/users/${item.admin.id}`">
                    <v-btn color="blue"> {{ item.admin.nick }}</v-btn>
                  </router-link>
                </template>
                <template v-slot:item.status="{ item }">
                  <div style="display: flex">
                    <v-chip
                      :color="item.paymentsStates.id === 2 ? 'green' : 'grey'"
                      >{{ item.paymentsStates.title }}
                    </v-chip>
                    <v-chip
                      :color="'orange'"
                      class="ml-2"
                      @click="changeStatusTransaction(item)"
                    >
                      Изменить
                    </v-chip>
                    <v-dialog
                      v-model="changeDialogShowProccess"
                      max-width="400"
                    >
                      <v-card>
                        <v-card-title class="headline"
                          >Изменить статус вручную</v-card-title
                        >
                        <v-card-text>
                          <div v-if="transactionsInProcess[editedIndex]">
                            ID Транзакции:
                            {{ transactionsInProcess[editedIndex].id }}
                          </div>
                          <div v-if="transactionsInProcess[editedIndex]">
                            Сумма:
                            {{
                              (
                                transactionsInProcess[editedIndex].amount / 100
                              ).toFixed(2)
                            }}
                            Руб.
                          </div>
                          <div>
                            <v-select
                              v-model="changeStatus"
                              label="Выберите статус"
                              :items="[
                                { value: 1, name: 'Создана' },
                                { value: 2, name: 'Выполнена' },
                                { value: 3, name: 'В процессе' },
                                { value: 10, name: 'Отменена' }
                              ]"
                              item-text="name"
                              item-value="value"
                            >
                            </v-select>
                          </div>
                          <div>
                            Комментарий:
                            <v-text-field
                                v-model="newCommentFromAdmin"
                                label="коментарий к транзакции"
                                single-line
                            />
                          </div>
                          <div>
<!--                            <v-file-input-->
<!--                                v-model="file"-->
<!--                                label="Загрузить чек (Не обязательно)"-->
<!--                                accept="image/*"-->
<!--                                outlined-->
<!--                            ></v-file-input>-->
                            <v-btn
                                @click="loadCheckModal = true"
                                color="primary"
                                x-large
                                outlined
                                class="mr-2"
                            >Загрузка чеков</v-btn
                            >
                          </div>
                          <div v-if="changeStatus === 10">
                            <v-select
                              v-model="reasonId"
                              label="Выберите причину"
                              :items="[
                                { value: 0, name: 'Неверные реквизиты' },
                                { value: 1, name: 'Проблемный банк' },
                                {
                                  value: 2,
                                  name: 'Отменен платежной системой'
                                },
                                { value: 3, name: 'Не отыгран депозит' },
                                {
                                  value: 4,
                                  name: 'Свяжитесь с тех поддержкой'
                                },
                                { value: 5, name: 'Своя причина' }
                              ]"
                              item-text="name"
                              item-value="value"
                            >
                            </v-select>
                            <div v-if="reasonId === 5">
                              Своя причина:
                              <v-text-field
                                v-model="reason"
                                label="Своя причина"
                                single-line
                              />
                            </div>
                          </div>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red"
                            text
                            @click="changeDialogShowProccess = false"
                            :disabled="isProcessing"
                          >
                            Нет
                          </v-btn>
                          <v-btn
                            color="primary"
                            text
                            @click="
                              manualyEdit(transactionsInProcess[editedIndex])
                            "
                            :disabled="isProcessing"
                          >
                            Да
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </template>
                <template v-slot:item.course="{ item }">
                  <span class="font-weight-bold">
                    {{ item.course.toFixed(2) }}
                  </span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span class="font-weight-bold">
                    {{ (item.amount / item.course / 100).toFixed(2) }}
                  </span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  {{ item.createdAt | moment("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  {{ item.updatedAt | moment("YYYY-MM-DD HH:mm:ss") }}
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="headersWithdraw"
                :items="filteredTransactionsInSuccess"
                :loading="loading"
                :items-per-page="18"
                loading-text="Загрузка... Пожалуйста подождите"
              >
                <template v-slot:item.user="{ item }">
                  <router-link tag="div" :to="`/users/${item.cards.id}`">
                    <v-btn color="primary"> {{ item.cards.nick }}</v-btn>
                  </router-link>
                </template>
                <template v-slot:item.commentFromAdmin="{ item }">
                  <div v-if="item.comment && item.comment.includes('bank_name')">
                    СБП - {{ JSON.parse(item.comment).bank_name }};
                  </div>
                  <div v-if="item.comment && !item.comment.includes('bank_name')">
                    {{ item.comment }}
                  </div>
                  <div v-if="item.commentFromAdmin">
                    Комментарий - {{ item.commentFromAdmin }}
                  </div>
                </template>
                <template v-slot:item.operator="{ item }">
                  <router-link tag="div" :to="`/users/${item.admin.id}`">
                    <v-btn color="blue"> {{ item.admin.nick }}</v-btn>
                  </router-link>
                </template>
                <template v-slot:item.status="{ item }">
                  <div style="display: flex">
                    <v-chip
                      :color="item.paymentsStates.id === 2 ? 'green' : 'grey'"
                      >{{ item.paymentsStates.title }}
                    </v-chip>
                    <v-chip
                      :color="'orange'"
                      class="ml-2"
                      @click="changeStatusTransactionInSuccess(item)"
                    >
                      Изменить
                    </v-chip>
                    <v-dialog v-model="changeDialogShowSuccess" max-width="400">
                      <v-card>
                        <v-card-title class="headline"
                          >Изменить статус вручную</v-card-title
                        >
                        <v-card-text>
                          <div v-if="transactionsInSuccess[editedIndex]">
                            ID Транзакции:
                            {{ transactionsInSuccess[editedIndex].id }}
                          </div>
                          <div v-if="transactionsInSuccess[editedIndex]">
                            Сумма:
                            {{
                              (
                                transactionsInSuccess[editedIndex].amount / 100
                              ).toFixed(2)
                            }}
                            Руб.
                          </div>
                          <div>
                            <v-select
                              v-model="changeStatus"
                              label="Выберите статус"
                              :items="[
                                { value: 1, name: 'Создана' },
                                { value: 2, name: 'Выполнена' },
                                { value: 3, name: 'В процессе' },
                                { value: 10, name: 'Отменена' }
                              ]"
                              item-text="name"
                              item-value="value"
                            >
                            </v-select>
                          </div>
                          <div>
                            Комментарий:
                            <v-text-field
                              v-model="newCommentFromAdmin"
                              label="коментарий к транзакции"
                              single-line
                            />
                          </div>
                          <div>
<!--                            <v-file-input-->
<!--                                v-model="file"-->
<!--                                label="Загрузить чек (Не обязательно)"-->
<!--                                accept="image/*"-->
<!--                                outlined-->
<!--                            ></v-file-input>-->
                            <v-btn
                                @click="loadCheckModal = true"
                                color="primary"
                                x-large
                                outlined
                                class="mr-2"
                            >Загрузка чеков</v-btn
                            >
                          </div>
                          <div v-if="changeStatus === 10">
                            <v-select
                              v-model="reasonId"
                              label="Выберите причину"
                              :items="[
                                { value: 0, name: 'Неверные реквизиты' },
                                { value: 1, name: 'Проблемный банк' },
                                {
                                  value: 2,
                                  name: 'Отменен платежной системой'
                                },
                                { value: 3, name: 'Не отыгран депозит' },
                                {
                                  value: 4,
                                  name: 'Свяжитесь с тех поддержкой'
                                },
                                { value: 5, name: 'Своя причина' }
                              ]"
                              item-text="name"
                              item-value="value"
                            >
                            </v-select>
                            <div v-if="reasonId === 5">
                              Своя причина:
                              <v-text-field
                                v-model="reason"
                                label="Своя причина"
                                single-line
                              />
                            </div>
                          </div>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red"
                            text
                            @click="changeDialogShowSuccess = false"
                          >
                            Нет
                          </v-btn>
                          <v-btn
                            color="primary"
                            text
                            @click="
                              manualyEdit(transactionsInSuccess[editedIndex])
                            "
                          >
                            Да
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </template>
                <template v-slot:item.course="{ item }">
                  <span class="font-weight-bold">
                    {{ item.course.toFixed(2) }}
                  </span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span class="font-weight-bold">
                    {{ (item.amount / item.course / 100).toFixed(2) }}
                  </span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  {{ item.createdAt | moment("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  {{ item.updatedAt | moment("YYYY-MM-DD HH:mm:ss") }}
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="headersDeposit"
                :items="filteredTransactionsDepositInProccess"
                :loading="loading"
                :items-per-page="18"
                loading-text="Загрузка... Пожалуйста подождите"
              >
                <template v-slot:item.user="{ item }">
                  <router-link tag="div" :to="`/users/${item.cards.id}`">
                    <v-btn color="primary"> {{ item.cards.nick }}</v-btn>
                  </router-link>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip
                    :color="item.paymentsStates.id === 2 ? 'green' : 'grey'"
                    >{{ item.paymentsStates.title }}
                  </v-chip>
                </template>
                <template v-slot:item.course="{ item }">
                  <span class="font-weight-bold">
                    {{ item.course.toFixed(2) }}
                  </span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span class="font-weight-bold">
                    {{ (item.amount / item.course / 100).toFixed(2) }}
                  </span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  {{ item.createdAt | moment("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  {{ item.updatedAt | moment("YYYY-MM-DD HH:mm:ss") }}
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="headersDeposit"
                :items="filteredTransactionsDepositInSuccess"
                :loading="loading"
                :items-per-page="18"
                loading-text="Загрузка... Пожалуйста подождите"
              >
                <template v-slot:item.user="{ item }">
                  <router-link tag="div" :to="`/users/${item.cards.id}`">
                    <v-btn color="primary"> {{ item.cards.nick }}</v-btn>
                  </router-link>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip
                    :color="item.paymentsStates.id === 2 ? 'green' : 'grey'"
                    >{{ item.paymentsStates.title }}
                  </v-chip>
                </template>
                <template v-slot:item.course="{ item }">
                  <span class="font-weight-bold">
                    {{ item.course.toFixed(2) }}
                  </span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span class="font-weight-bold">
                    {{ (item.amount / item.course / 100).toFixed(2) }}
                  </span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  {{ item.createdAt | moment("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  {{ item.updatedAt | moment("YYYY-MM-DD HH:mm:ss") }}
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-row class="mt-3">
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menu"
                    v-model="firstMenu"
                    :close-on-content-click="false"
                    :return-value.sync="dateStart"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateStart"
                        label="Показать с"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateStart" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="firstMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dateStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menu2"
                    v-model="lastMenu"
                    :close-on-content-click="false"
                    :return-value.sync="dateEnd"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateEnd"
                        label="до"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateEnd" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="lastMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(dateEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-btn x-large color="primary" @click="getStatisticDeposit"
                    >Показать</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="[{ text: 'Депозитов всего', value: 'amount' }]"
                    :items="transactionsDepositStatistic"
                    :loading="loading"
                    :items-per-page="18"
                    loading-text="Загрузка... Пожалуйста подождите"
                  >
                    <template v-slot:item.amount="{ item }">
                      {{
                        (item.amount / 100)
                          | number("0,0", { thousandsSeparator: " " })
                      }}
                      Руб.
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="mt-3">
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menu"
                    v-model="firstMenu"
                    :close-on-content-click="false"
                    :return-value.sync="dateStart"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateStart"
                        label="Показать с"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateStart" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="firstMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dateStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menu2"
                    v-model="lastMenu"
                    :close-on-content-click="false"
                    :return-value.sync="dateEnd"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateEnd"
                        label="до"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dateEnd" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="lastMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(dateEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-btn x-large color="primary" @click="getStatisticWithdraw"
                    >Показать</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="[
                      { text: 'ID', value: 'id' },
                      { text: 'Оператор', value: 'nick' },
                      { text: 'Общая сумма вывода', value: 'amount' }
                    ]"
                    :items="transactionsWithdrawStatistic"
                    :loading="loading"
                    :items-per-page="18"
                    loading-text="Загрузка... Пожалуйста подождите"
                  >
                    <template v-slot:item.amount="{ item }">
                      {{
                        (item.amount / 100)
                          | number("0,0", { thousandsSeparator: " " })
                      }}
                      Руб.
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import moment from "moment";
import PaymentsService from "@/services/payments.service";
import imageCompression from "browser-image-compression";

export default {
  name: "WithdrawHistory",
  data: () => ({
    editedIndex: 0,
    changeStatus: 2,
    changeDialogShowSuccess: false,
    changeDialogShowProccess: false,
    search: "",
    isProcessing: false,
    newCommentFromAdmin: "",
    reason: "",
    reasonId: 0,
    transactionsInProcess: [],
    transactionsInSuccess: [],
    transactionsDepositInSuccess: [],
    transactionsDepositInProccess: [],
    transactionsWithdrawStatistic: [],
    transactionsDepositStatistic: [],
    tab: 0,
    dateStart: moment().format("YYYY-MM-01"),
    dateEnd: moment()
      .add("1", "month")
      .format("YYYY-MM-01"),
    firstMenu: false,
    lastMenu: false,
    headersWithdraw: [
      { text: "ID", value: "id" },
      { text: "ID PG", value: "externalID" },
      { text: "Пользователь", value: "user" },
      { text: "Оператор", value: "operator" },
      { text: "Валюта", value: "payerCurrency" },
      { text: "Курс", value: "course" },
      { text: "Сумма", value: "amount" },
      { text: "Платежный метод", value: "paymentsWays.name" },
      { text: "Платежный шлюз", value: "paymentsSystems.title" },
      { text: "Комментарий", value: "commentFromAdmin" },
      { text: "Статус", value: "status" },
      { text: "Реквизиты", value: "purseNumber" },
      { text: "Чек", value: "url" },
      { text: "Дата создания", value: "createdAt" },
      { text: "Дата Последнего обновления", value: "updatedAt" }
    ],
    headersDeposit: [
      { text: "ID", value: "id" },
      { text: "ID PG", value: "externalID" },
      { text: "Пользователь", value: "user" },
      { text: "Оператор", value: "operator" },
      { text: "Валюта", value: "payerCurrency" },
      { text: "Курс", value: "course" },
      { text: "Сумма", value: "amount" },
      { text: "Платежный метод", value: "paymentsWays.name" },
      { text: "Платежный шлюз", value: "paymentsSystems.title" },
      { text: "Комментарий", value: "commentFromAdmin" },
      { text: "Статус", value: "status" },
      { text: "Реквизиты", value: "purseNumber" },
      { text: "Дата создания", value: "createdAt" },
      { text: "Дата Последнего обновления", value: "updatedAt" }
    ],
    loading: false,
    file: null,
    loadCheckModal: false,
    checksList: []
  }),
  computed: {
    ...mapGetters("user", ["user", "getProfileAdmin"]),
    filteredTransactionsInProccess() {
      if (!this.search) {
        return this.transactionsInProcess;
      } else {
        return this.transactionsInProcess.filter(transition => {
          return (
            transition.id.toString().includes(this.search) ||
            transition.purseNumber.toString().includes(this.search)
          );
        });
      }
    },
    filteredTransactionsInSuccess() {
      if (!this.search) {
        return this.transactionsInSuccess;
      } else {
        return this.transactionsInSuccess.filter(transition => {
          return (
            transition.id.toString().includes(this.search) ||
            transition.purseNumber.toString().includes(this.search)
          );
        });
      }
    },
    filteredTransactionsDepositInSuccess() {
      if (!this.search) {
        return this.transactionsDepositInSuccess;
      } else {
        return this.transactionsDepositInSuccess.filter(transition => {
          return transition.id.toString().includes(this.search);
        });
      }
    },
    filteredTransactionsDepositInProccess() {
      if (!this.search) {
        return this.transactionsDepositInProccess;
      } else {
        return this.transactionsDepositInProccess.filter(transition => {
          return transition.id.toString().includes(this.search);
        });
      }
    }
  },
  methods: {
    changeStatusTransaction(item) {
      console.log(item);
      this.editedIndex = this.transactionsInProcess.indexOf(item);
      this.changeDialogShowProccess = true;
      this.reason = "";
      this.newCommentFromAdmin =
        this.transactionsInProcess[this.editedIndex].commentFromAdmin || "";
    },
    changeStatusTransactionInSuccess(item) {
      console.log(item);
      this.editedIndex = this.transactionsInSuccess.indexOf(item);
      this.changeDialogShowSuccess = true;
      this.reason = "";
      this.newCommentFromAdmin =
        this.transactionsInSuccess[this.editedIndex].commentFromAdmin || "";
    },
    getHistoryInProccess() {
      this.loading = true;
      PaymentsService.findMany({
        where: {
          state: 3,
          code: 2,
          isFake: 0,
          createdAt: {
            gt: moment()
              .subtract(21, "days")
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate()
          }
        },
        include: {
          cards: true,
          paymentsWays: true,
          paymentsCodes: true,
          paymentsStates: true,
          paymentsSystems: true,
          admin: true
        },
        orderBy: [{ state: "desc" }, { id: "desc" }],
        take: 100
      }).then(data => {
        this.loading = false;
        this.transactionsInProcess = data;
      });
    },
    getHistoryInSuccess() {
      this.loading = true;
      PaymentsService.findMany({
        where: {
          state: 2,
          code: 2,
          isFake: 0
        },
        include: {
          cards: true,
          paymentsWays: true,
          paymentsCodes: true,
          paymentsStates: true,
          paymentsSystems: true,
          admin: true
        },
        orderBy: [{ state: "desc" }, { id: "desc" }],
        take: 100
      }).then(data => {
        this.loading = false;
        this.transactionsInSuccess = data;
      });
    },
    getHistoryDepositInSuccess() {
      this.loading = true;
      PaymentsService.findMany({
        where: {
          state: 2,
          code: 1
        },
        include: {
          cards: true,
          paymentsWays: true,
          paymentsCodes: true,
          paymentsStates: true,
          paymentsSystems: true
        },
        orderBy: [{ state: "desc" }, { id: "desc" }],
        take: 100
      }).then(data => {
        this.loading = false;
        this.transactionsDepositInSuccess = data;
      });
    },
    getHistoryDepositInProccess() {
      this.loading = true;
      PaymentsService.findMany({
        where: {
          state: 1,
          code: 1
        },
        include: {
          cards: true,
          paymentsWays: true,
          paymentsCodes: true,
          paymentsStates: true,
          paymentsSystems: true
        },
        orderBy: [{ state: "desc" }, { id: "desc" }],
        take: 100
      }).then(data => {
        this.loading = false;
        this.transactionsDepositInProccess = data;
      });
    },
    getStatisticWithdraw() {
      this.loading = true;
      PaymentsService.getStatisticWithdrawAdmin({
        dateStart: this.dateStart,
        dateEnd: this.dateEnd
      }).then(data => {
        this.loading = false;
        this.transactionsWithdrawStatistic = data;
      });
    },
    getStatisticDeposit() {
      this.loading = true;
      PaymentsService.getStatisticDepositUsers({
        dateStart: this.dateStart,
        dateEnd: this.dateEnd
      }).then(data => {
        this.loading = false;
        this.transactionsDepositStatistic = data;
      });
    },
    async manualyEdit(item) {
      // Проверка на уже идущую операцию
      if (this.isProcessing) return;

      this.isProcessing = true; // Установка флага блокировки
      try {
        // Основная логика выполнения
        const data = await PaymentsService.findUnique({
          where: {
            id: item.id
          }
        });
        if (data.state !== item.state) {
          this.$noty.error("У вас неоктуальная информация о платеже");
          this.getHistoryInProccess();
          this.getHistoryInSuccess();
          this.getHistoryDepositInSuccess();
          this.getHistoryDepositInProccess();
          this.changeDialogShowProccess = false;
          this.editedIndex = 0;
          this.reason = "";
          this.newCommentFromAdmin = "";
        } else {
          await PaymentsService.update({
            data: {
              state: this.changeStatus,
              admin_id: this.getProfileAdmin || 2,
              commentFromAdmin: this.newCommentFromAdmin,
              reason: this.changeStatus === 10 ? this.reason : "",
              system: 100
            },
            where: {
              id: item.id
            }
          });
          this.$noty.success("Успешно");
          await this.saveCheque(item.id)
          this.getHistoryInProccess();
          this.getHistoryInSuccess();
          this.getHistoryDepositInSuccess();
          this.getHistoryDepositInProccess();
          this.changeDialogShowProccess = false;
          this.editedIndex = 0;
          this.reason = "";
          this.newCommentFromAdmin = "";
        }
      } catch (e) {
        this.$noty.error(e.message);
      } finally {
        this.isProcessing = false; // Разблокировка
      }
    },
    async saveCheque(paymentId) {
      const fileList = await this.generatedBase64List(this.checksList);
      if (fileList.length) {
        PaymentsService.saveChequeWithdraw({
          data: {
            paymentId: paymentId,
            checksList: fileList
          }
        }).then(data => {
          this.file = null;
          this.checksList = [];
          if (!data.success) {
            this.$noty.error(`чек не сохранён - ${data.err}`);
          }
        });
      } else {
        if (this.checksList.length) {
          this.$noty.error(
              "чек не сохранён - Размер pdf файлов превышает лимит"
          );
        }
      }
    },
    async fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(new Error("Ошибка чтения файла"));
        reader.readAsDataURL(file); // Преобразуем файл в Base64
      });
    },
    async generatedBase64List(files) {
      const maxTotalSizeKB = 700;
      let options = {
        maxSizeMB: 0.15,
        maxWidthOrHeight: 1024
      };

      // Разделяем файлы на изображения и PDF
      const imageFiles = files.filter(file => file.type.startsWith("image/"));
      const nonImageFiles = files.filter(
          file => !file.type.startsWith("image/")
      );

      const nonImageFilesSize = nonImageFiles.reduce(
          (total, file) => total + file.size,
          0
      );
      if (nonImageFilesSize > maxTotalSizeKB * 1024) {
        return [];
      }

      // Сжимаем только изображения
      let compressedImages = await Promise.all(
          imageFiles.map(file => imageCompression(file, options))
      );

      // Проверяем общий размер
      while (
          [...compressedImages, ...nonImageFiles].reduce(
              (total, file) => total + file.size,
              0
          ) >
          maxTotalSizeKB * 1024
          ) {
        // Уменьшаем параметры компрессии
        options.maxWidthOrHeight *= 0.9;

        // Пересжимаем изображения
        compressedImages = await Promise.all(
            imageFiles.map(file => imageCompression(file, options))
        );
      }

      // Комбинируем обработанные изображения и необработанные файлы
      const processedFiles = [...compressedImages, ...nonImageFiles];

      // Преобразуем файлы в Base64
      return await Promise.all(
          processedFiles.map(file => this.fileToBase64(file))
      );
    },
    addCheckToList() {
      if (
          this.file instanceof Blob &&
          (this.file.type.startsWith("image/") || this.file.type.includes("/pdf"))
      ) {
        this.checksList.push(this.file);
        this.file = null;
      } else {
        this.$noty.error("Файл не выбран или тип не поддерживаеться");
      }
    },
    deleteCheckFromList(fileIndex) {
      this.checksList.splice(fileIndex, 1);
    }
  },
  created() {
    this.getHistoryInProccess();
    this.getHistoryInSuccess();
    this.getHistoryDepositInSuccess();
    this.getHistoryDepositInProccess();
  }
};
</script>

<style scoped></style>
